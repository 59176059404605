<template>
  <component :is="'b-card'">

    <div v-if="error" class="bg-danger p-2 text-white border-r">
        <h4 class="alert-heading">
            Erro ao editar página
        </h4>
        <div class="alert-body">
            {{error}}
        </div>
    </div>
    <b-form class="mt-1" @submit.prevent="submit">
      <b-row>
        <b-col
          cols="12"
        >
            <h3 class=" mb-3">Editar página</h3>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Titulo"
            label-for="title"
          >
            <b-form-input
              id="title"
              required
              v-model="data.title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Descrição"
            label-for="description"
          >
            <div class="message-editor">
              <quill-editor
                id="quil-content"
                required
                v-model="data.description"
                :options="editorOption"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Descrição do rodapé"
            label-for="description_bottom"
          >
            <div class="message-editor">
              <quill-editor
                id="quil-content"
                required
                v-model="data.description_bottom"
                :options="editorOption"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
            <hr>
            <h3 class="mb-3">Meta</h3>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Url"
            label-for="url"
          >
            <b-form-input
              id="url"
              required
              v-model="data.url"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Titulo"
            label-for="meta_title"
          >
            <b-form-input
              id="meta_title"
              required
              v-model="data.meta.title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Descrição"
            label-for="meta_description"
          >
            <b-form-textarea
              id="meta_description"
              required
              v-model="data.meta.description"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Kaywords"
            label-for="keywords"
          >
            <b-form-textarea
              required
              id="keywords"
              v-model="data.meta.keywords"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button style="width: 100%" class="mt-2 py-1" variant="primary" type="submit"><!-- apps-pages-add -->
        Atualizar
      </b-button>

    </b-form>

  </component>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BCard, BButton,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import pageStoreModule from '../pageStoreModule'

export default {
  components: {
    // BTab,
    // BTabs,
    BButton,
    BCard,
    // BAlert,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    // BLink,
    quillEditor,
  },
  setup() {
    const PAGE_APP_STORE_MODULE_NAME = 'app-page'

    // Register module
    if (!store.hasModule(PAGE_APP_STORE_MODULE_NAME)) store.registerModule(PAGE_APP_STORE_MODULE_NAME, pageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PAGE_APP_STORE_MODULE_NAME)
    })

    return {}
  },
  data() {
    return {
      editorOption: {
        placeholder: 'Mensagem',
      },
      id: 1,
      error: null,
      data: {
        title: '',
        description: '',
        description_bottom: '',
        lang: 'BR',
        meta: {
          title: '',
          keywords: '',
          description: '',
        },
        url: '',
      },
    }
  },
  mounted() {
    this.id = router.currentRoute.params.id
    store.dispatch('app-page/fetchPage', { id: this.id })
      .then(response => {
        this.data.title = response.title
        this.data.description = response.description
        this.data.description_bottom = response.description_bottom
        this.data.lang = response.lang
        this.data.url = response.url
        this.data.meta.title = response.meta.title
        this.data.meta.keywords = response.meta.keywords
        this.data.meta.description = response.meta.description
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.error = 'Rota não encontrada'
        } else {
          this.error = 'Erro ao buscar página, tenta novamente'
        }
      })
  },
  methods: {
    async submit() {
      if (this.validateForm(this.data)) {
        store.dispatch('app-page/editPage', { id: this.id, payload: this.data })
          .then(() => {
            this.$router.push({ name: 'apps-pages-list' })
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.error = 'Rota não encontrada'
            } else {
              this.error = 'Erro ao atualizar página, tenta novamente'
            }
          })
      }
    },
    validateForm(data) {
      if (data.description === '') {
        this.error = 'Preencha os dados da descrição'
        return false
      }
      if (data.description_bottom === '') {
        this.error = 'Preencha os dados da descrição do rodapé'
        return false
      }
      this.error = null
      return true
    },
  },
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
.border-r{
    border-radius: 10px;
}
form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
